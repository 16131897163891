<template>
    <section class="content">
        <div style="background-color: #0093E9; background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%); width: height:200px; padding: 76px 30px 40px 30px;">
            <h5 style="color: white; text-align: center;">Total yang harus dibayar</h5>
            <legend style="text-align: center; color: white; font-weight: 600;">Rp.2.500.000</legend>
        </div>
        <div class="card" style="margin-top: -28px;width: 80%;margin-left: auto;margin-right: auto;">
            <div class="card-body">
                <form role="form" @submit="submitUploadBukti">
                    <div class="col-md-12 form-group" style="text-align: center;">
                        <a href="/"><img height="64" src="@/assets/img/logo-bri.png"></a>
                    </div>
                    <div class="mb-3" style="text-align: center;">Kirim biaya pendaftaran melalui bank bri dengan rekening 000123212300 a.n Randy Orto</div>
                    <div class="col-md-12 form-group">
                        <label for="inputFile">Bukti bayar</label>
                            <input type="hidden" name="img_code" value="" v-model="form.img_code" id="img_code" />
                        <div class="custom-file">
                            <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFoto(this)">
                            <label class="custom-file-label" for="inputFile">Choose file</label>
                        </div>
                    </div>
                    <div class="social-auth-links text-center mb-3">
                        <button type="submit" class="btn btn-block btn-primary btn-lg" :disabled="loading">
                            <span v-if="loading" style="margin-bottom: 2px;" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{ loading?" loading...":"Kirim bukti bayar" }} <i class="fa fa-sign-in-alt"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
    import { auth, authFetch, createTable } from "@/libs/hxcore";
    import $ from 'jquery';
    import bg from "@/assets/img/bgwhite.png"
    import Swal from "sweetalert2";

    let idr = Intl.NumberFormat("en-ID", {
        style: "currency",
        currency: "IDR",
        useGrouping: true,
        maximumSignificantDigits: 3,
    });

    export default {
        name: 'CompletePayment',
        components: {
        },
        data() {
            return {
                errors: [],
                loading: false,
                failed: false,
                failedMsg: '',
                method: '',
                form: [],
                formUpload: [],
                formDoUpload: [],
                info: []
            }
        },
        created: function() {
            $('body').addClass('hold-transition login-page');
            $('body').removeAttr('style');
            $('.login-page').attr('style', "background: #F5F5F5");

            var user = auth.user();
            authFetch('/masterdata/siswa/info/'+user.id)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.info = js.data;
            })
        },
        methods: {
            submitUploadBukti: function(ev) {
                var self = this;
                const e = this.$refs;
                this.loading = true;
                this.failed = false;

                var file_data = $('#inputFile').prop('files');
                console.log(file_data)
                if (file_data.length == 0) {
                    Swal.fire({
                        icon: 'info',
                        title: 'Informasi',
                        text: 'Mohon upload bukti transfer!'
                    })
                    return false;
                } else {
                    authFetch('/masterdata/siswa_register/upload_data', {
                        method: 'POST',
                        body: this.formDoUpload,
                        headers: {
                          'Content-Type': null,
                        }
                    }).then((res) => {
                        this.loading = false;
                        if (res.status === 201) {

                        } else if (res.status === 400) {}

                        return res.json();
                        
                    }).then(js => {
                        this.errors = [];
                            if (!js.status) {
                                for (var key in js) {
                                    if (js.hasOwnProperty(key)) {
                                        this.errors.push(js[key])
                                    }
                                }

                                return;
                            } else {
                                $(e.formUploadBukti).modal('hide');
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Informasi',
                                    text: 'Data telah tersimpan.'
                                })
                                this.formUpload.id =  ''
                                this.formUpload.code =  ''
                                this.formUpload.nominal =  0
                                this.formUpload.nilai =  0
                                this.formUpload.pembayaran =  0
                                this.formUpload.kekurangan =  0
                                this.$router.push("/persetujuan");
                            }
                    })
                }
                ev.preventDefault();
            },
            uploadFoto : function (file) {
                var self = this;
                const e = this.$refs;
                var file_data = $('#inputFile').prop('files');
                var formUpload = new FormData();
                var fileInput = $('#inputFile')[0];
                $.each(fileInput.files, function(k,file){
                  formUpload.append('file', file);
                  $(e.imgname).html(file.name);
                });

                formUpload.append('subdir', 'pembayaran/'+this.info.id_registrasi);
                formUpload.append('id', this.info.id_registrasi);
                formUpload.append('nominal', this.info.total);
                self.formDoUpload = formUpload
            },
        }
    }

</script>
<style lang="scss">
    .card {
        margin-bottom: 24px;
        -webkit-box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    }
    #app {
        width: 100%;
        height: 100%;
    }
    .login-page,
    .register-page {
        -ms-flex-align: center;
        align-items: center;
        background: white;
        @media (max-width: 576px){
            .login-box, .register-box {
                margin-top: 20px;
                width: 360px; 
            }
        }

        .login-box {
            height: 100vh;
            position: relative;

            .form-control {
                height: calc(2rem + 2px);
                border:0;
            }

            .card {

                border-top-left-radius: 20px !important;
                border-top-right-radius: 20px !important;
            }

            .login-card-body,
            .register-card-body {
                border-top-left-radius: 20px !important;
                border-top-right-radius: 20px !important;
                background-color: #F1F1FA;
                .input-group .input-group-text
                {
                    background-color:#fff;
                    border:0;
                }
            }

            form {
                bottom: 0;
                position: absolute;
                width: 100%;
            }
        }
    }
</style>